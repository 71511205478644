import React from "react";
import {renderRichText} from "gatsby-source-contentful/rich-text";

class VoiceSection extends React.Component {
    render() {
        return (
            <div className={'container container-slim'}>
                <div className={'flex flex-row items-center'}>
                    <div className={'basis-full'}>
                        <div className={'voice-container'}>
                            <div className={'flex flex-wrap items-center justify-between'}>
                                <div className={'basis-full md:basis-1/4'}>
                                    <h3>{this.props.contentful.title}</h3>
                                </div>
                                <div className={'basis-full md:basis-6/12'}>
                                    <p>{renderRichText(this.props.contentful.body)}</p>
                                    <a href={this.props.contentful.callToActionUrl} target={'_blank'} rel={'noreferrer'}>
                                        <button className={'mt-9'}>{this.props.contentful.callToActionText}</button>
                                    </a>
                                    {this.props.contentful.callToActionUrlThree &&
                                        <a href={this.props.contentful.callToActionUrlTwo} target={'_blank'} rel={'noreferrer'} className={'ml-2'}>
                                            <button className={'mt-9'}>{this.props.contentful.callToActionTextTwo}</button>
                                        </a>
                                    }
                                    {this.props.contentful.callToActionUrlThree &&
                                        <a href={this.props.contentful.callToActionUrlThree} target={'_blank'} rel={'noreferrer'} className={'ml-2'} >
                                            <button className={'mt-9'}>{this.props.contentful.callToActionTextThree}</button>
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VoiceSection;
