import React from "react";
import {renderRichText} from "gatsby-source-contentful/rich-text";

class WhatsDifferentSection extends React.Component {
    render() {
        return (
            <div className={'container container-slim'}>
                <div className={'flex flex-row items-end justify-between'}>
                    <div className={'basis-1/4'}>
                        <h3>{this.props.contentful.title}</h3>
                    </div>
                    <div className={'basis-auto pb-3.5 hidden md:flex'}>
                        {(this.props.contentful.callToActionUrl && this.props.contentful.callToActionText) &&
                            <a href={this.props.contentful.callToActionUrl} target={'_blank'} rel={'noreferrer'}>
                                <button>{this.props.contentful.callToActionText}</button>
                            </a>
                        }
                    </div>
                </div>
                <div className={'flex flex-row'}>
                    <div className={'flex flex-col basis-full inner-text'}>
                        {renderRichText(this.props.contentful.body)}
                    </div>
                </div>
                <div className={'flex flex-row'}>
                    <div className={'flex flex-col basis-auto block md:hidden pt-7'}>

                        <a href={this.props.contentful.callToActionUrl} target={'_blank'} rel={'noreferrer'}>
                            <button>{this.props.contentful.callToActionText}</button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default WhatsDifferentSection;
