import {graphql, useStaticQuery} from "gatsby";
import * as React from "react";

const AlertBar = () => {
    const alertBarQuery = useStaticQuery(graphql`
      query AlertBar {
        contentfulAlertBanner(contentful_id: {eq: "1br1IwBCuo4v0gDn1oK1Bq"}) {
          text
          ctaText
          ctaUrl
        }
      }
    `);
    const alertBar = alertBarQuery.contentfulAlertBanner;
    return (
        <div className={`banner ${alertBar.isEnabled && 'show'}`}>
            <div className={'banner__inner'}>
            {alertBar.text}
            <a href={alertBar.ctaUrl}>{alertBar.ctaText}</a>
            </div>
        </div>
    )
}

export default AlertBar;
