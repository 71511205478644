import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';


const TweetCard = ({index,tweet}) => {
        return (
                <div className={'p-3'}>
                        <TwitterTweetEmbed
                            tweetId={tweet.tweetId}
                        />
                </div>

        );
}

export default TweetCard;
