import {graphql} from "gatsby";
import * as React from "react";
import {Helmet} from "react-helmet";
import {SEO} from "../components/seo";
import Header from "../components/Header";
import Fullpage from "../contents/homepage";
import AlertBar from "../components/AlertBar";

const IndexPage = ({data}) => {
    return (
        <div>
            <Helmet>
                <title>Inter Protocol</title>
            </Helmet>
            <AlertBar/>
            <Header/>
            <Fullpage contentfulPage={data.allContentfulPage.edges[0].node}
                      blogPosts={data.allContentfulBlogPost.nodes}/>
        </div>
    )
}

export default IndexPage

export const Head = () => (
    <SEO/>
)

export const query = graphql`
    query {
        allContentfulPage(filter: {id: {eq: "c910c9d5-b5fd-5a0d-af55-66dedc77e638"}}) {
            edges {
                node {
                    id
                    title
                    sections {
                        ... on ContentfulCallToActionWithInfoBlock {
                            id
                            title
                            node_locale
                            callToActionUrl
                            callToActionText
                            internal {
                                type
                            }
                            body {
                                raw
                            }
                        }
                        ... on ContentfulHeader {
                            id
                            callToActionText
                            callToActionUrl
                            callToActionTextTwo
                            callToActionUrlTwo
                            internal {
                                type
                            }
                            node_locale
                            title
                            subtitle {
                                raw
                            }
                        }
                        ... on ContentfulTweetsSection {
                            id
                            callToActionUrl
                            callToActionText
                            title
                            internal {
                                type
                            }
                            tweets {
                                tweetId
                            }
                        }
                        ... on ContentfulVideoSection {
                            id
                            body {
                                raw
                            }
                            title
                            additionalText
                            additionalTextColor
                            internal {
                                type
                            }
                        }
                        ... on ContentfulUseCasesSection {
                            id
                            title
                            internal {
                                type
                            }
                            elements {
                                title
                                body {
                                    raw
                                }
                                callToActionText
                                callToActionUrl
                            }
                        }
                        ... on ContentfulHowItWorksSection {
                            id
                            title
                            internal {
                                type
                            }
                            elements {
                                title
                                body {
                                    raw
                                }
                                internal {
                                    type
                                }
                            }
                            subtitle {
                                raw
                            }
                        }
                        ... on ContentfulVoiceSection {
                            id
                            body {
                                raw
                            }
                            title
                            callToActionUrl
                            callToActionText
                            internal {
                                type
                            }
                        }
                        ... on ContentfulWhatPeopleSaysSection {
                            id
                            subtitle {
                                raw
                            }
                            title
                            internal {
                                type
                            }
                            elemets {
                                title
                                videoUrl
                                thumbnail {
                                    url
                                    title
                                }
                                publishedAt(formatString: "MMMM DD, YYYY")
                            }
                        }
                        ... on ContentfulBlogSection {
                            id
                            title
                            internal {
                                type
                            }
                            blogPosts {
                                id
                                title
                                slug
                                excerpt
                                image {
                                    title
                                    url
                                }
                                thumbnail {
                                    url
                                    title
                                }
                                publishedAt
                            }
                        }
                        ... on ContentfulRoadmapSection {
                            id
                            internal {
                                type
                            }
                            title
                            body {
                                raw
                            }
                            twitterUrl
                            discordUrl
                            elements {
                                id
                                title
                                body {
                                    raw
                                }
                                isChecked
                            }
                        }
                    }
                }
            }
        }
        allContentfulBlogPost(limit: 3, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                id
                thumbnail {
                    url
                    title
                }
                excerpt
                title
                slug
                publishedAt(formatString: "MMMM DD, YYYY")
            }
        }
    }
`
