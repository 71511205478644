import React from "react";
import {Link} from "gatsby";
import BlogPreview from "../../components/blogPreview";

function BlogSection(props) {
    const blogPosts = props.blogPosts;
    return (
        <div className={'relative container container-slim'}>
            <div className={'flex flex-wrap justify-between items-center mb-6 md:mb-14'}>
                <div className={'basis-full md:basis-auto'}>
                    <h3>{props.contentful.title}</h3>
                </div>

                <div className={'basis-full hidden md:flex md:basis-auto'}>
                    <Link to={'/blog'}>
                        <button>
                            View Blog
                        </button>
                    </Link>
                </div>
            </div>
            <div className={'flex md:gap-14 flex-wrap md:flex-nowrap'}>
                {blogPosts.map((post, index) => {
                    return(
                        <div className={'basis-full md:basis-1/3 pb-14'} key={index}>
                            <BlogPreview post={post}/>
                        </div>
                    )
                })}
            </div>

            <div className={'basis-full md:hidden'}>
                <Link to={'/blog'}>
                    <button>
                        View Blog
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default BlogSection
