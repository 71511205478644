import React from "react";
import Videos from "../../components/Videos";
import {renderRichText} from "gatsby-source-contentful/rich-text";

class WhatPeopleSays extends React.Component {
    render() {
        return (
            <div className={'container container-slim'}>
                <div className={'relative'}>
                    <h3>{this.props.contentful.title}</h3>
                    <h6 className={'max-w-md'}>{renderRichText(this.props.contentful.subtitle)}</h6>

                    <Videos elements={this.props.contentful.elemets}/>
                </div>
            </div>
        );
    }
}

export default WhatPeopleSays
