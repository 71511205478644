import React from 'react';
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {BLOCKS} from "@contentful/rich-text-types";

function VaultTeaser({contentful}) {
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) =>{
				return (
					<div className={"basis-full inner-text"}>{children}</div>
				)
			},
		},
	};

	return (
		<div className={"container container-slim"}>
			<div className="vault-container">
				<div className={"flex flex-wrap items-end justify-between"}>
					<div className={"basis-full md:basis-1/2 pb-20"}>
						<h3 className="title">
							{contentful.title}
						</h3>
						{contentful.body && renderRichText(contentful.body, options)}
					</div>
					<div className={"basis-full md:basis-auto md:flex"}>
						<a href={contentful.callToActionUrl} target={"_blank"} rel={"noreferrer"}>
							<button>{contentful.callToActionText}</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default VaultTeaser;