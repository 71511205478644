import React from 'react';
import {renderRichText} from "gatsby-source-contentful/rich-text";

import twitter from "../../images/social-twitter.svg";
import discord from "../../images/social-discord.svg";

const RoadmapSection = ({contentful}) => {
    return (
        <div className={'container container-slim'}>
            <div className={'flex flex-row flex-wrap'}>
                <div className={'basis-full pb-16 lg:pb-0 lg:basis-1/2'}>
                    <h3>{contentful.title}</h3>
                    <div className={'subtext'}>
                        {renderRichText(contentful.body)}
                    </div>

                    <div className={'socials'}>
                        <div className={'flex'}>
                            <div className={'basis-auto'}>
                                <a href={contentful.twitterUrl} target={'_blank'} rel={'noreferrer'}>
                                    <img src={twitter} alt="Twitter"/>
                                </a>
                            </div>
                            <div className={'basis-auto pl-4'}>
                                <a href={contentful.discordUrl} target={'_blank'} rel={'noreferrer'}>
                                    <img src={discord} alt="Twitter"/>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={'basis-full lg:basis-1/2'}>
                    <ul className={'roadmap'}>
                        {contentful.elements.map((element, index) => {
                            return (
                                <li>
                                    <div className={`roadmap-element ${element.isChecked && 'active'}`}>
                                        <div className={`title`}>{element.title}</div>
                                        {renderRichText(element.body)}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default RoadmapSection;
