import React from "react";

import video3 from "../../videos/video3.mp4"
import video3webm from "../../videos/video3.webm"
import video3hero from "../../videos/video3-poster.png"
import {renderRichText} from "gatsby-source-contentful/rich-text";
import video3Image from "../../images/video-image-3-bg.png";

class VideoSection3 extends React.Component {
    render() {
        return (
            <div className={'container'}>
                <div className={'flex flex-wrap lg:flex-nowrap items-center h-full'}>
                    <div className={'basis-full lg:basis-1/2 lg:pr-52 order-2 lg:order-1 lg:pl-16'}>
                        <div className={'video-section-content'}>
                            <h2>
                                {this.props.contentful.title}
                                <div className={`additional-text ${this.props.contentful.additionalTextColor}`}>{this.props.contentful.additionalText}</div>
                            </h2>
                            <p>
                                {renderRichText(this.props.contentful.body)}
                            </p>
                        </div>
                    </div>
                    <div className={'basis-full lg:basis-1/2 h-full order-1 lg:order-2'}>
                        <div className={'video-container relative'}>
                            <video autoPlay={true} preload={true} loop={true} playsInline={true} muted={true} poster={this.props.image ? video3Image : video3hero}>
                                {!this.props.image && <source src={video3} type='video/mp4;codecs="hvc1"'/>}
                                {!this.props.image && <source src={video3webm} type="video/webm" />}
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoSection3;
