const decodeParagraph = (json) => {
    const parsedJson = JSON.parse(json);
    const lines = parsedJson.content[0].content.map(function (line) {
        return line.value;
    });

    return (lines.join(' '));
}

export default decodeParagraph
