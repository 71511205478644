import React from "react";
import {Link} from "gatsby";
import video from "../../videos/hero.mp4"
import videowebm from "../../videos/hero.webm"
import videohero from "../../videos/hero-hero.png"

import video1hero from "../../videos/vault-poster.png";
import video1webm from "../../videos/vault.webm";

import decodeParagraph from "../../contentful/ParagraphDecoder";
import Popup from "../../components/launchPopup";

class HeroSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
        };
    }

    showLaunchPopup = () =>{
        //this.setState({showPopup:true});
    }
    hideLaunchPopup = () =>{
        //this.setState({showPopup:false});
    }

    render() {
        return (
            <div className={'container container-slim'}>

                <Popup showPopup={this.state.showPopup} showLaunchPopup={this.showLaunchPopup} hideLaunchPopup={this.hideLaunchPopup}/>

                <div className={'video-container'}>
                    <video autoPlay={true} preload={true} loop={true} playsInline={true} muted={true} poster={this.props.vaultsPage ? video1hero : videohero}>
                        <source src={this.props.vaultsPage ? video1webm : videowebm} type="video/webm" />
                    </video>
                </div>
                <div className={'flex items-center content-center h-full'}>
                    <div className={'flex flex-col basis-full sm:basis-8/12'}>
                        <h1 className={'uppercase'}>{this.props.contentful.title}</h1>
                        <div className={'text'}>
                            {decodeParagraph(this.props.contentful.subtitle.raw)}

                            <div className={'block mt-10'}>
                                {!this.props.vaultsPage &&
                                    <a href={this.props.contentful.callToActionUrlTwo} target={'_blank'}>
                                        <button id={'launch-app'} className={'mr-4'} onClick={this.showLaunchPopup}>
                                            {this.props.contentful.callToActionTextTwo}
                                        </button>
                                    </a>
                                }
                                <Link to={this.props.contentful.callToActionUrl}>
                                    <button className={'btn btn-read mt-8'}>
                                        {this.props.contentful.callToActionText}
                                        <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g transform="translate(-496.000000, -9929.000000)">
                                                    <g transform="translate(380.000000, 9499.000000)">
                                                        <g transform="translate(0.000000, 428.000000)">
                                                            <g transform="translate(116.000000, 2.000000)">
                                                                <circle fill="#000000" cx="11" cy="11" r="11"/>
                                                                <polyline stroke="#FFFFFF" strokeWidth="2" points="9.82553858 7.88083042 12.9243114 10.9796032 9.82553858 14.0783761"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeroSection;
