import React from "react";
import useCaseImage1 from '../../images/use-case-image1.png';
import useCaseImage2 from '../../images/use-case-image2.png';
import vaultImage1 from '../../images/OracleNetwork.png';
import vaultImage2 from '../../images/Reserve.png';
import vaultImage3 from '../../images/use-case-image1.png';
import vaultImage4 from '../../images/Funnel.png';
import {renderRichText} from "gatsby-source-contentful/rich-text";
import { BLOCKS } from '@contentful/rich-text-types';

class UseCasesSection extends React.Component {
    render() {
        const options = {
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => <div className={'text mt-5'}>{children}</div>,
            },
        };

        const useCaseImages = [
            useCaseImage1,
            useCaseImage2
        ];

        const vaultImages = [
            vaultImage1,
            vaultImage2,
            vaultImage3,
            vaultImage4
        ]

        const vaultPage = this.props.vaultsPage

        return (
            <div className={`${vaultPage && 'mb-20'}`}>
                <div className={'container container-slim'}>
                    <div className={'flex flex-row items-end justify-between'}>
                        <div className={'basis-auto'}>
                            <h3>{this.props.contentful.title}</h3>
                        </div>
                    </div>
                </div>
                <div className={'container'}>
                    <div className={'use-case-columns'}>
                        <div className={'flex flex-row flex-wrap mt-12'}>
                            {
                                this.props.contentful.elements.map(function (element, index) {
                                    return (
                                        <div className={'flex flex-col basis-full md:basis-6/12'}>
                                            <div className={'use-case use-case-left'}>
                                                <div className={'use-case-container'}>
                                                    <div className={'use-case-inner'}>
                                                        <div className={`flex image ${index === 3 ? 'items-start' : 'items-end'}`}>
                                                            <img src={vaultPage ? vaultImages[index] : useCaseImages[index]} alt={element.title}/>
                                                        </div>
                                                        <h3>{element.title}</h3>
                                                        {renderRichText(element.body, options)}
                                                        <a href={element.callToActionUrl} target={'_blank'}>
                                                            <button className={'mt-8'}>{element.callToActionText}</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UseCasesSection;
