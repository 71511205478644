import React from "react";

import image1 from '../../images/Overcollateralization.svg';
import image2 from '../../images/LiquidationEngine.svg';
import image3 from '../../images/ParityStabilityModule.svg';
import image4 from '../../images/ReservePool.svg';

import vaultsImage1 from '../../images/Oracle.png';
import vaultsImage2 from '../../images/LiquidationEngine.svg';
import vaultsImage3 from '../../images/Auction.png';
import vaultsImage4 from '../../images/ReservePool.svg';


import {renderRichText} from "gatsby-source-contentful/rich-text";
import {BLOCKS} from "@contentful/rich-text-types";

class HowItWorks extends React.Component {
    render() {
        const options = {
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => <div className={'text'}>{children}</div>,
            },
        };
        return (
            <div className={'container container-slim'}>
                <h3>{this.props.contentful.title}</h3>
                <h6>{this.props.contentful.subtitle && renderRichText(this.props.contentful.subtitle)}</h6>

                <div className={'flex flex-wrap mt-16'}>
                    <div className={'flex flex-col basis-full md:basis-6/12 pr-5'}>
                        <div className={'flex justify-between'}>
                            <div className={'flex flex-col'}>
                                <div className={'image h-40'}>
                                    <img src={this.props.vaultsPage ? vaultsImage1 : image1} alt={this.props.contentful.elements[0].title} className={'max-h-full'}/>
                                </div>
                            </div>
                        </div>
                        <h4>{this.props.contentful.elements[0].title}</h4>
                        {this.props.contentful.elements[0].body && renderRichText(this.props.contentful.elements[0].body, options)}
                    </div>
                    <div className={'flex flex-col basis-full md:basis-6/12 pt-12 md:pt-0 md:pl-5'}>
                        <div className={'flex justify-between'}>
                            <div className={'flex flex-col'}>
                                <div className={'image h-40'}>
                                    <img src={this.props.vaultsPage ? vaultsImage2 : image2} alt={this.props.contentful.elements[1].title} className={'max-h-full'}/>
                                </div>
                            </div>
                        </div>
                        <h4>{this.props.contentful.elements[1].title}</h4>
                        {this.props.contentful.elements[0].body && renderRichText(this.props.contentful.elements[0].body, options)}
                    </div>
                </div>

                <div className={'flex flex-wrap mt-16 mb-24 md:mb-16'}>
                    <div className={'flex flex-col basis-full md:basis-6/12 pr-5'}>
                        <div className={'flex justify-between'}>
                            <div className={'flex flex-col'}>
                                <div className={'image h-40'}>
                                    <img src={this.props.vaultsPage ? vaultsImage3 : image3} alt={this.props.contentful.elements[2].title} className={'max-h-full'}/>
                                </div>
                            </div>
                        </div>
                        <h4>{this.props.contentful.elements[2].title}</h4>
                        {this.props.contentful.elements[2].body && renderRichText(this.props.contentful.elements[2].body, options)}
                    </div>
                    <div className={'flex flex-col basis-full md:basis-6/12 pt-12 md:pt-0 md:pl-5'}>
                        <div className={'flex justify-between'}>
                            <div className={'flex flex-col'}>
                                <div className={'image h-40'}>
                                    <img src={this.props.vaultsPage ? vaultsImage4 : image4} alt={this.props.contentful.elements[3].title} className={'max-h-full'}/>
                                </div>
                            </div>
                        </div>
                        <h4>{this.props.contentful.elements[3].title}</h4>
                        {this.props.contentful.elements[3].body && renderRichText(this.props.contentful.elements[3].body, options)}
                    </div>
                </div>
            </div>
        );
    }
}

export default HowItWorks;
