import React from "react";
import {Link} from "gatsby";
import Moment from 'moment';

class BlogPreview extends React.Component {
    render() {
        let image = {
            backgroundImage: 'url('+(this.props.post.image && this.props.post.image.url)+')'
        }

        if (this.props.post.thumbnail) {
            image = {
                backgroundImage: 'url('+(this.props.post.thumbnail.url)+')'
            }
        }

        return (
            <Link to={`/blog/${this.props.post.slug}`}>
                <div className={'blog-preview'}>
                    <div className={'image'} style={image}/>
                    <div className={'text'}>
                        <div className={'date'}>{Moment(this.props.post.publishedAt).format('MMMM DD ,YYYY')}</div>
                        <div className={'title'}>{this.props.post.title}</div>
                        <div className={'excerpt'}>{this.props.post.excerpt}</div>
                        <button className={'btn btn-read'}>
                            Read More...
                            <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-496.000000, -9929.000000)">
                                        <g transform="translate(380.000000, 9499.000000)">
                                            <g transform="translate(0.000000, 428.000000)">
                                                <g transform="translate(116.000000, 2.000000)">
                                                    <circle fill="#000000" cx="11" cy="11" r="11"/>
                                                    <polyline stroke="#FFFFFF" strokeWidth="2" points="9.82553858 7.88083042 12.9243114 10.9796032 9.82553858 14.0783761"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
            </Link>
        );
    }
}

export default BlogPreview;
