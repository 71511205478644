import React from "react";
import {Link} from "gatsby";

import video2 from "../../videos/vault_www.mp4"
import video2webm from "../../videos/vault_www.webm"
import video2hero from "../../images/vaults-www.png"
import {renderRichText} from "gatsby-source-contentful/rich-text";
import video2Image from "../../images/video-image-2-bg.png";

class VideoSection2 extends React.Component {
    render() {
        return (
            <div className={'container'}>
                <div className={`flex flex-wrap lg:flex-nowrap items-center h-full ${this.props.reverse && 'flex-row-reverse reverse-video'}`}>
                    <div className={'basis-full lg:basis-1/2 lg:pr-16 order-2 lg:order-1 lg:pl-52'}>
                        <div className={'video-section-content'}>
                            <h2>
                                {this.props.contentful.title}
                                <div className={`additional-text ${this.props.contentful.additionalTextColor}`}>{this.props.contentful.additionalText}</div>
                            </h2>
                            <p>
                                {renderRichText(this.props.contentful.body)}
                            </p>

                            {!this.props.image &&
                            <a href={'http://psm.inter.trade'} target={'_blank'} rel={'noreferrer'}>
                                <button className={'btn btn-read mt-8'}>
                                    Learn more
                                    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g transform="translate(-496.000000, -9929.000000)">
                                                <g transform="translate(380.000000, 9499.000000)">
                                                    <g transform="translate(0.000000, 428.000000)">
                                                        <g transform="translate(116.000000, 2.000000)">
                                                            <circle fill="#000000" cx="11" cy="11" r="11"/>
                                                            <polyline stroke="#FFFFFF" strokeWidth="2" points="9.82553858 7.88083042 12.9243114 10.9796032 9.82553858 14.0783761"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </a>}
                        </div>
                    </div>
                    <div className={'basis-full lg:basis-1/2 h-full order-1 lg:order-2'}>
                        <div className={'video-container relative'}>
                            <video autoPlay={true} preload={true} loop={true} playsInline={true} muted={true} poster={this.props.image ? video2Image : video2hero}>
                                {!this.props.image && <source src={video2} type='video/mp4;codecs="hvc1"'/>}
                                {!this.props.image && <source src={video2webm} type="video/webm" />}
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoSection2;
