import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import HeroSection from "./Home/hero-section";
import WhatsDifferentSection from "./Home/whats-different-section";
import RecentTweetsSection from "./Home/recent-tweets-section";
import VideoSection1 from "./Home/video-section1";
import VideoSection2 from "./Home/video-section2";
import VideoSection3 from "./Home/video-section3";
import Footer from "../components/Footer";
import UseCasesSection from "./Home/use-cases-section";
import HowItWorks from "./Home/how-it-works-section";
import VoiceSection from "./Home/voice-section";
import WhatPeopleSays from "./Home/what-people-says";
import BlogSection from "./Home/blog-section";
import RoadmapSection from "./Home/roadmap-section";
import VaultTeaser from "./Home/vault-teaser";

class Fullpage extends React.Component {
    filterContentfulSections = (filter, onlyOne = true) => {
        const section = this.props.contentfulPage.sections.filter(function (section) {
            return section.internal.type === filter;
        });
        if (onlyOne) {
            return (section[0]);
        }

        return section;
    }

    onLeave(origin, destination, direction) {
        // arguments are mapped in order of fullpage.js callback arguments do something
        // with the event


        origin.item.classList.add('leave');
        origin.item.classList.remove('slide-down');
        origin.item.classList.remove('slide-up');

        if(direction === 'down'){
            destination.item.classList.remove('slide-down');
            destination.item.classList.add('slide-up');
        }
        if(direction === 'up'){
            destination.item.classList.remove('slide-up');
            destination.item.classList.add('slide-down');
        }

        if(destination.item.querySelector('video') !== null){
            const nextVideo = destination.item.querySelector('video');
            nextVideo.play();
        }

        if(origin.item.querySelector('video') !== null){
            const prevVideo = origin.item.querySelector('video');
            prevVideo.pause();
        }

        setTimeout(function(){
            origin.item.classList.remove('leave');
        },100)

        if(destination.item.classList.contains('white-section')){
            document.getElementById("header").classList.add('colorful');
        }else{
            document.getElementById("header").classList.remove('colorful');
        }

        if(destination.item.classList.contains('video-section') && direction === 'down'){
            document.getElementById("header").classList.add('hidden-header');
        }else{
            document.getElementById("header").classList.remove('hidden-header');
        }

        if(destination.item.classList.contains('video-section')){
            document.getElementById("fp-nav").classList.add('fp-nav-show');
        }else{
            document.getElementById("fp-nav").classList.remove('fp-nav-show');
        }

        if(destination.index === 0){
            document.getElementById("header").classList.remove('with-bg');
        }else{
            document.getElementById("header").classList.add('with-bg');
        }
    }

    render(){
        
        return (
            <div>
                <div className={'section hero-section fp-auto-height-responsive'}>
                    <HeroSection contentful={this.filterContentfulSections('ContentfulHeader')}/>
                </div>
                <div className={'section vault-teaser fp-auto-height'}>
                    <VaultTeaser contentful={this.filterContentfulSections('ContentfulCallToActionWithInfoBlock', false)[0]}/>
                </div>
                <div className={'section whats-different-section fp-auto-height'}>
                    <WhatsDifferentSection contentful={this.filterContentfulSections('ContentfulCallToActionWithInfoBlock', false)[1]}/>
                </div>
                <div className={'section recent-tweets-section fp-auto-height white-section'}>
                    <RecentTweetsSection contentful={this.filterContentfulSections('ContentfulTweetsSection')}/>
                </div>
                <div className={'section video-section white-section fp-auto-height-responsive'}>
                    <VideoSection1 contentful={this.filterContentfulSections('ContentfulVideoSection', false)[1]}/>
                </div>
                <div className={'section video-section white-section fp-auto-height-responsive'}>
                    <VideoSection2 reverse={true} contentful={this.filterContentfulSections('ContentfulVideoSection', false)[0]}/>
                </div>
                <div className={'section video-section white-section fp-auto-height-responsive hidden'}>
                    <VideoSection3 contentful={this.filterContentfulSections('ContentfulVideoSection', false)[2]}/>
                </div>
                <div className={'section white-section fp-auto-height-responsive'}>
                    <div className={'use-cases-section'}>
                        <UseCasesSection vaultsPage={false} contentful={this.filterContentfulSections('ContentfulUseCasesSection')}/>
                    </div>
                    <div className={'roadmap-section'}>
                        <RoadmapSection contentful={this.filterContentfulSections('ContentfulRoadmapSection')}/>
                    </div>
                    <div className={'how-it-works-section'}>
                        <HowItWorks contentful={this.filterContentfulSections('ContentfulHowItWorksSection')}/>
                    </div>
                    <div className={'voice-section'}>
                        <VoiceSection contentful={this.filterContentfulSections('ContentfulVoiceSection')}/>
                    </div>
                    <div className={'what-people-says-section'}>
                        <WhatPeopleSays contentful={this.filterContentfulSections('ContentfulWhatPeopleSaysSection')}/>
                    </div>
                    <div className={'blog-section'}>
                        <BlogSection contentful={this.filterContentfulSections('ContentfulBlogSection')} blogPosts={this.props.blogPosts}/>
                    </div>
                    <div className={'footer-section'}>
                        <Footer/>
                    </div>
                </div>
            </div>
        )
    }
}


export default Fullpage;
